import React from "react";
import OurProcess from "../../images/our-process.svg";

const HowWeWorks = () => {
  return (
    <div id="section-how-we-works">
      <div className="section-who-we-works-with-top">
        <div className="title-1 mb-3">How we work</div>
        <p className="section-discription">
          We are dedicated to our client’s success and committed to quality,
          elimination of inefficiency and increase of productivity.
        </p>
        <p className="section-discription">
          Every client we partner with is assigned a dedicated account manager
          to keep all parties, the project and progress on track, and we employ
          a structured framework to ensure all outcomes are successfully
          delivered on time and on budget.
        </p>
      </div>
      <div className="text-center mb-4">
        <img src={OurProcess} style={{ maxWidth: "800px" }} />
      </div>
      {/* <div className="section-who-we-works-with-bottom">
        <div className="section-who-we-works-with-process">
          <div className="process-card">
            <div className="process-card-num">01</div>
            <div className="process-card-text">Plan</div>
          </div>
          <div className="process-card">
            <div className="process-card-num">02</div>
            <div className="process-card-text">Prioritize</div>
          </div>
          <div className="process-card">
            <div className="process-card-num">03</div>
            <div className="process-card-text">Commit</div>
          </div>
          <div className="process-card">
            <div className="process-card-num">04</div>
            <div className="process-card-text">Deliver</div>
          </div>
          <div className="process-card">
            <div className="process-card-num">05</div>
            <div className="process-card-text">Review</div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default HowWeWorks;
