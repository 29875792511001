import React from "react";
import SauceAI from '../images/home-page/logo/sauce-ai-logo.png';

const TrustedClients = (props) => {
  return (
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-4 col-lg-4 p-0">
                <div className="image-wrap img-matt"></div>
            </div>
            <div className="col-12 col-md-8 col-lg-8">
                <div className="client-comment">
                    <div>
                        <p>“I've worked with iTelaSoft on multiple projects now (both engineering and design) including a full web app build and new website. Always very impressed with the teams quality, speed of execution and communication. Every project has exceeded expectations.”
                        </p> 
                    </div>
                    <div className="client-dets">
                        <div>
                            <h6>Matt Hinds</h6>
                            <p>Sauce AI</p>
                        </div>
                        <div>
                            <img className="" alt="sauce-ai-logo" src={SauceAI} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default TrustedClients;
