import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import about1 from "../images/about1.jpg";
import about2 from "../images/about2.jpg";

const OurMission = () => {
  const data = useStaticQuery(graphql`
    query {
      OurValues: file(relativePath: { eq: "about1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      LifeAt: file(relativePath: { eq: "about2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className="section-our-mission-wrap">
        <div className="title-1 mb-3">Our mission</div>
      <div className="section-our-mission top">
        <div className="section-our-mission-image">
          {" "}
          <img src={about1} className="img-fluid" />
          {/* <Img
            fluid={data.OurValues.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Hero"
          /> */}
        </div>
        <div className="section-our-mission-text">
          <div className="page-careers-dec">
          We’re driven by our passion to solve business problems with technological innovation, and our decades of experience have demonstrated to us that realising the full potential of technology is achievable for every business.
          </div>
        </div>
      </div>

      <div className="section-our-mission bottom">
        <div className="section-our-mission-text">
          <div className="page-careers-dec">
            We’ve been leading the way in innovative technology design &
            delivery since 2009 and work closely with you to understand your
            goals and adapt our solutions to perfectly match your needs. From
            new product design & development to optimisation of existing
            processes, our team are all under one roof and with you every step
            of the way to ensure exceptional outcomes for your business.
          </div>
        </div>
        <div className="section-our-mission-image">
          {" "}
          
          <img src={about2}  className="img-fluid" />
          {/* <Img
            fluid={data.LifeAt.childImageSharp.fluid}
            loading="eager"
            fadeIn={true}
            alt="Hero"
          /> */}
        </div>
      </div>
    </div>
  );
};

export default OurMission;
