import React from "react";

import ErajRatnayake from "../../images/Clients/ErajRatnayake.jpg";
import BenTaylor from "../../images/Clients/BenTaylor.jpg";
import EmilioGonzalez from "../../images/Clients/EmilioGonzalez.jpg";
import MandeepSodhi from "../../images/Clients/MandeepSodhi.jpg";
import MustafaBensan from "../../images/Clients/MustafaBensan.jpg";
import StefanZurflueh from "../../images/Clients/StefanZurflueh.jpg";
import RobbieBrender from "../../images/Clients/RobbieBrender.jpg";
import NikkiTaylor from "../../images/Clients/NikkiTaylor.jpg";
import MattHinds from "../../images/Clients/MattHinds.jpg";
import HomeHeroImg from "../../images/home-hero.jpg";
import { Col, Container, Row } from "reactstrap";

import SvgIconsSet from "../SvgIconsSet";
import Loadable from '@loadable/component';
const OwlCarousel = Loadable(() => import('react-owl-carousel3'));
const options = {
  loop: true,
  nav: true,
  dots: true,
  autoplay: true,
  autoplayHoverPause: false,
  autowidth: true,
  // margin: 10,
  autoplayTimeout: 3500, // 3.5 seconds for each slide to stay
  autoplaySpeed: 1000, // 1 second for the transition animation
  animateOut: 'fadeOut',       // Fade out animation
  animateIn: 'fadeIn',          // Fade in animation
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 1,
    },
    1200: {
      items: 1,
    },
  },
};

const BannerCarousel = () => {
  return (
    <div className="banner-carousel">
      <div className="banner-carousel-inner">
        <OwlCarousel className="owl-theme" {...options}>
              <div class="owl-item slide-1">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="hero-title">
                      Unlocking business opportunities with technological innovation{" "}
                    </div>
                  </Col>
                </Row>
              </Container>
              </div>
              <div class="owl-item slide-2">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="hero-title">
                      Transform your business with our AI enablement framework{" "}
                    </div>
                  </Col>
                </Row>
              </Container>
              </div>
        </OwlCarousel>
      </div>
    </div>
  );
};

export default BannerCarousel;
