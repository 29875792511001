import React from "react";
import Projuno from '../images/home-page/logo/projuno-logo.png';

const TrustedClients = (props) => {
  return (
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-4 col-lg-4 p-0">
                <div className="image-wrap img-robbie"></div>
            </div>
            <div className="col-12 col-md-8 col-lg-8">
                <div className="client-comment">
                    <div>
                        <p>“The iTelaSoft team has provided an enormous amount of value to the Projuno product. Be it through specific feature implementation or integration or for a dedicated resource who works within our team, we know that we can rely on iTelaSoft for whatever development needs we may have, to provide quality development work and resourcing.”
                        </p> 
                    </div>
                    <div className="client-dets">
                        <div>
                            <h6>Robbie Brender</h6>
                            <p>Projuno</p>
                        </div>
                        <div>
                            <img className="" alt="projuno-logo" src={Projuno} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default TrustedClients;
