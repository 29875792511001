import React from "react";
import Symbox from '../images/home-page/logo/symbox-logo.png';

const TrustedClients = (props) => {
  return (
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-4 col-lg-4 p-0">
                <div className="image-wrap img-emili"></div>
            </div>
            <div className="col-12 col-md-8 col-lg-8">
                <div className="client-comment">
                    <div>
                        <p>“When Symbox was acquired by 10Pearls, it brought many challenges. I’d like to praise the iTelaSoft team on how they supported us every step of the way in navigating the legal and compliance changes we needed to manage. From knowledge transfer, customer retention and service continuity, iTelaSoft were the steady ship we could rely on to ensure delivery never stopped. iTelaSoft is held in my highest regard as a partner, and I would not hesitate to work with them again.”
                        </p> 
                    </div>
                    <div className="client-dets">
                        <div>
                            <h6>Emilio Gonzalez</h6>
                            <p>Symbox</p>
                        </div>
                        <div>
                            <img className="" alt="symbox-logo" src={Symbox} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default TrustedClients;
