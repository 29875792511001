import React from "react";
import Prodapt from '../images/home-page/logo/prodapt-logo.png';

const TrustedClients = (props) => {
  return (
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-4 col-lg-4 p-0">
                <div className="image-wrap img-nikki"></div>
            </div>
            <div className="col-12 col-md-8 col-lg-8">
                <div className="client-comment">
                    <div>
                        <p>“Management always professional, easy to work with and responsive in support of solutions and queries. Team always worked in most efficient manner and to time. Highly recommend iTelaSoft services to others.”
                        </p> 
                    </div>
                    <div className="client-dets">
                        <div>
                            <h6>Nikki Taylor</h6>
                            <p>Prodapt Consulting</p>
                        </div>
                        <div>
                            <img className="" alt="prodapt-consulting-logo" src={Prodapt} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default TrustedClients;
