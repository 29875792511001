import React from "react";
import Stride from '../images/home-page/logo/stride-logo.png';

const TrustedClients = (props) => {
  return (
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-4 col-lg-4 p-0">
                <div className="image-wrap img-stefan"></div>
            </div>
            <div className="col-12 col-md-8 col-lg-8">
                <div className="client-comment">
                    <div>
                        <p>“iTelasoft has consistently proven itself to be a strong and reliable partner. During the launch of our platform, we identified several opportunities to improve the UX. We requested changes, and iTelasoft implemented them without any delays or impact on the user experience. iTelasoft has demonstrated multiple times its ability to meet ambitious timeline, and we value what the team brings in terms of new ideas. We’re excited to continue working with iTelasoft on enhancing our digital investment platform.”
                        </p> 
                    </div>
                    <div className="client-dets">
                        <div>
                            <h6>Stefan Zurflueh</h6>
                            <p>Stride Equity</p>
                        </div>
                        <div>
                            <img className="" alt="stride-equity-logo" src={Stride} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default TrustedClients;
