import React from 'react';

import Helmet from 'react-helmet';
import { Container } from 'reactstrap';
import Seo from '../components/Seo';
import Layout from '../layouts/index';
import Partners from '../components/indexPage/Partners';
import ServiceCard from '../components/ServiceCard';
import TabcmpSet from '../components/tab/TabcmpSet';
import GetInTouchbtn from '../components/GetInTouchbtn';
import SvgIconsSet from '../components/SvgIconsSet';
import OurPartners from '../components/OurPartners';
import OurMission from '../components/OurMission';
import HowWeWorks from '../components/homePage/HowWeWorks';
import BannerCarousel from '../components/homePage/bannercarousel';
import Impact from '../components/homePage/Impact';
import StrategyDesign from '../images/strategy-and-design.jpg';
import BuildEngineer from '../images/build-and-engineer.jpg';
import ServicesResources from '../images/services-and-resources.jpg';

import ClientTestimonial1 from '../components/ClientTestimonialRobbiePossibl';
import ClientTestimonial2 from '../components/ClientTestimonialErajLilardia';
import ClientTestimonial3 from '../components/ClientTestimonialGaryPokitpal';
import ClientTestimonial4 from '../components/ClientTestimonialMustafaDataGlider';
import ClientTestimonial5 from '../components/ClientTestimonialBenOxygen';
import ClientTestimonial6 from '../components/ClientTestimonialStefanStrideEquity';
import ClientTestimonial7 from '../components/ClientTestimonialEmilioSymbox';
import ClientTestimonial8 from '../components/ClientTestimonialMandeepEffi';
import ClientTestimonial9 from '../components/ClientTestimonialNikkiProdapt';
import ClientTestimonial10 from '../components/ClientTestimonialErajSEAEasyCapital';
import ClientTestimonial11 from '../components/ClientTestimonialRobbieProjuno';
import ClientTestimonial12 from '../components/ClientTestimonialMattSauceAI';

import RobbieBrender from '../images/Clients/RobbieBrender.jpg';
import ErajRatnayake from '../images/Clients/ErajRatnayake.jpg';
import BenTaylor from '../images/Clients/BenTaylor.jpg';
import MustafaBensan from '../images/Clients/MustafaBensan.jpg';
import EmilioGonzalez from '../images/Clients/EmilioGonzalez.jpg';
import MandeepSodhi from '../images/Clients/MandeepSodhi.jpg';
import StefanZurflueh from '../images/Clients/StefanZurflueh.jpg';
import NikkiTaylor from '../images/Clients/NikkiTaylor.jpg';
import MattHinds from '../images/Clients/MattHinds.jpg';
import GaryCobain from '../images/Clients/GaryCobain.jpeg';

const Home = () => {
  const tabData = [
    {
      label: 'Gary Cobain',
      text: 'PokitPal',
      image: GaryCobain,
      content: <ClientTestimonial3 />,
    },
    {
      label: 'Robbie Brender',
      text: 'Possibl',
      image: RobbieBrender,
      content: <ClientTestimonial1 />,
    },
    {
      label: 'Eraj Ratnayake',
      text: 'Lilardia Capital',
      image: ErajRatnayake,
      content: <ClientTestimonial2 />,
    },
    {
      label: 'Mustafa Benson',
      text: 'Data Glider',
      image: MustafaBensan,
      content: <ClientTestimonial4 />,
    },
    {
      label: 'Ben Taylor',
      text: 'Oxygen Broking Services',
      image: BenTaylor,
      content: <ClientTestimonial5 />,
    },
    {
      label: 'Stefan Zurflueh',
      text: 'Stride Equity',
      image: StefanZurflueh,
      content: <ClientTestimonial6 />,
    },
    {
      label: 'Emilio Gonzalez',
      text: 'Symbox',
      image: EmilioGonzalez,
      content: <ClientTestimonial7 />,
    },
    {
      label: 'Mandeep Sodhi',
      text: 'Effi',
      image: MandeepSodhi,
      content: <ClientTestimonial8 />,
    },
    {
      label: 'Nikki Taylor',
      text: 'Prodapt Consulting',
      image: NikkiTaylor,
      content: <ClientTestimonial9 />,
    },
    {
      label: 'Eraj Ratnayake',
      text: 'SEA Easy Capital',
      image: ErajRatnayake,
      content: <ClientTestimonial10 />,
    },
    {
      label: 'Robbie Brender',
      text: 'Projuno',
      image: RobbieBrender,
      content: <ClientTestimonial11 />,
    },
    {
      label: 'Matt Hinds',
      text: 'Sauce AI',
      image: MattHinds,
      content: <ClientTestimonial12 />,
    },
  ];

  return (
    <Layout bodyClass="page-index">
      <Seo title="Home" />
      <Helmet>
        <meta
          name="description"
          content="Innovation Partner - IoT, Mobile Apps &amp; Software Development, Cloud Engineering | iTelaSoft"
        />
      </Helmet>

      {/* home banner */}

      <BannerCarousel />

      <Container>
        <div className="section-padding pt-3" id="Sfsolution">
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h2 className="title-1 mb-2">
                    Agile, innovative and impactful software engineering
                    solutions
                  </h2>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <ServiceCard
                    image={StrategyDesign}
                    title="Strategy & Design"
                    description="We take you from ideation to delivery and beyond"
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <ServiceCard
                    image={BuildEngineer}
                    title="Build & Engineer"
                    description="If you dream it, we can build it"
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <ServiceCard
                    image={ServicesResources}
                    title="Managed Services & Resources"
                    description="Create capacity and efficiency in your business"
                  />
                </div>
                <div className="col-12">
                  <p className="my-2">
                    Our extensive team of software engineers, designers and
                    developers gives you access to the right skills and
                    experience needed to deliver real business impact and cost
                    efficiencies. Smart design, agile delivery and vendor
                    management is in our DNA, ensuring exceptional outcomes for
                    you - every time.
                  </p>
                  <a
                    href="/services"
                    className="btn btn-primary btn-icon-button"
                  >
                    <span className="btn-text">Learn more</span>
                    <span className="btn-icon">
                      <SvgIconsSet.SideArrow />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className="section-padding mt-4" id="section-who-we-works-with">
          <div className="section-who-we-works-with-top">
            <h3 className="title-1">Who we work with</h3>
            <div className="section-discription">
              We work with businesses of all sizes including Startup/Scaleups,
              Mid-size businesses and Large Enterprises across the globe. Our
              solutions can be adapted for just about any industry, and to date,
              some of the companies we&apos;ve worked with include:
            </div>
          </div>
          <Partners />
          {/* <div className="wr-tabcmpset">
            <TabcmpSet tabData={tabData} />
          </div> */}
          <div className="home-getting-touch">
            <div className="getting-touch">
              <GetInTouchbtn textdce="Let’s talk!" />
            </div>
          </div>
        </div>
        <div className="section-padding">
          <OurPartners />
        </div>

        <div className="section-padding">
          <OurMission />
        </div>
      </Container>

      <section className="mt-3 pb-3" style={{ background: '#efefef' }}>
        <Container>
          <div className="section-padding">
            <HowWeWorks />
          </div>
        </Container>
      </section>

      <section className="client-testimonial-section">
        <Container>
          <TabcmpSet tabData={tabData} tabNum={12} />
        </Container>
      </section>

      <Container>
        {/* <div className="section-padding section-faq">
          <div className="title-1 mb-2">Frequently Asked Questions</div>
          <NewAccordion items={items} />
        </div> */}

        {/* <div className="section-padding">
          <div className="title-1 mb-2">Resources</div>
          <Resources />
        </div> */}
        <div className="section-padding pb-4">
          <Impact />
        </div>
      </Container>
    </Layout>
  );
};

export default Home;
