import React from "react";
import SEAEasyCapital from '../images/home-page/logo/sea-easy-capital-logo.png';

const TrustedClients = (props) => {
  return (
    <div className="container">
        <div className="row">
            <div className="col-12 col-md-4 col-lg-4 p-0">
                <div className="image-wrap img-eraj"></div>
            </div>
            <div className="col-12 col-md-8 col-lg-8">
                <div className="client-comment">
                    <div>
                        <p>“Itelasoft employees that I engaged with were all very high caliber, who went over and beyond their remits to ensure SEA Easy Capital platform was commercially released. Always very helpful, diligent and have made themselves available readily. They have shown flexibility in their approach to engaging with SEA Easy Capital and take a very holistic view of client management. An excellent people organization to deal with, would highly recommend them.”
                        </p> 
                    </div>
                    <div className="client-dets">
                        <div>
                            <h6>Eraj Ratnayake</h6>
                            <p>SEA Easy Capital</p>
                        </div>
                        <div>
                            <img className="" alt="sea-easy-capital-logo" src={SEAEasyCapital} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default TrustedClients;
